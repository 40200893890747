<template>
<div id="play_space_container">
  <div class="connections">
    <canvas id="app-canvas"></canvas>
  </div>
</div>
</template>
 
<script>
//import * as PIXI from 'pixi.js'
import gameMission from '/public/simple-machines/src/missions/missions.json';
import menuComponents from '/public/simple-machines/src/ui/menu/menuComponents.json';

// Creates an alert that asks if the player is sure that they want to reload the site (and lose all progress)
// window.onbeforeunload = function() {
//   return "Data will be lost if you leave the page, are you sure?";
// };

export default {
    name: "SimpleMachines",
    computed: {
    },
    methods: {
        async init(){
            //let 
            let isHost = true; // true if they are the host.
            let userToken = "abc";
            let trackingService = this.tracking;
            /* global SimpleMachinesApp */
            let StartApp = function(scope) {
                let scale = window.innerHeight<768 ? window.innerHeight/768 : 1;  // Measure window height and scale down if necessary
                //let scale = 1;    
                let h = 768;
                let w = 1024; 
                /*if(self.innerHeight < self.innerWidth) {
                    h = self.innerHeight;
                    w = self.innerHeight * 1.33333;
                } else {
                    w = self.innerWidth;
                    h = self.innerWidth * 1.33333;
                }*/                                              // Or just set the scale to 1

                let app = new SimpleMachinesApp(h, w, scale, '/simple-machines/src', gameMission, menuComponents);
                app.setup();
                // MOVED Pusher setup so that it's shared across the apps
                app.setTrackingService(trackingService);
                
            }

            // Load public js scripts
            //
            if (typeof SimpleMachinesApp === 'function') {
                StartApp(this);
            }
            else {
                // console.log('Loading SimpleMachinesApp...');
                let scriptsLoaded = 0;

                // Load scripts
                let scriptNames = [
                    "/simple-machines/src/libs/liquidfun_google.js",
                    "/simple-machines/src/libs/pixi-5.3.5.min.js",
                    "/simple-machines/src/libs/pixi-filters.js",
                    "/simple-machines/src/utils.js",
                    "/simple-machines/src/ui/LoadScreen.js",
                    "/simple-machines/src/ui/MissionSelectScreen.js",
                    "/simple-machines/src/ui/MissionIntroScreen.js",
                    "/simple-machines/src/ui/MissionReportScreen.js",
                    "/simple-machines/src/ui/TimerOverlay.js",
                    "/simple-machines/src/ui/TimerOverlayCompletion.js",
                    "/simple-machines/src/ui/StartHereOverlay.js",
                    "/simple-machines/src/ui/ScrollContainer.js",
                    "/simple-machines/src/ui/MissionTutorialScreen.js",
                    "/simple-machines/src/ui/SandboxTutorialScreen.js",
                    "/simple-machines/src/ui/TimerMissionExplanationScreen.js",
                    "/simple-machines/src/ui/menu/ComponentMenuBar.js",
                    "/simple-machines/src/ui/onboarding_cards/PlaybackOnboardingCard.js",
                    "/simple-machines/src/ui/onboarding_cards/TimerOnboardingCard.js",
                    "/simple-machines/src/ui/onboarding_cards/SandboxOnboardingCard.js",
                    "/simple-machines/src/ui/ConfirmationScreen.js",
                    "/simple-machines/src/ui/UIUtils.js",
                    "/simple-machines/src/ui/TimerCountdown.js",
                    "/simple-machines/src/components/computils.js",
                    "/simple-machines/src/components/catapult.js",
                    "/simple-machines/src/components/dispenser.js",
                    "/simple-machines/src/components/rocks/RockOne.js",
                    "/simple-machines/src/components/rocks/RockTwo.js",
                    "/simple-machines/src/components/rocks/RockThree.js",
                    "/simple-machines/src/components/rocks/RockFour.js",
                    "/simple-machines/src/components/dominos/BlueDomino.js",
                    "/simple-machines/src/components/dominos/PinkDomino.js",
                    "/simple-machines/src/components/dominos/PurpleDomino.js",
                    "/simple-machines/src/components/dominos/TealDomino.js",
                    "/simple-machines/src/components/dominos/YellowDomino.js",
                    "/simple-machines/src/components/platforms/PlatformWhite.js",
                    "/simple-machines/src/components/platforms/PlatformConstellation.js",
                    "/simple-machines/src/components/signs/BananaSign.js",
                    "/simple-machines/src/components/signs/LightningSign.js",
                    "/simple-machines/src/components/signs/RainbowSign.js",
                    "/simple-machines/src/components/signs/UnicornSign.js",
                    "/simple-machines/src/components/shuttles/ShuttleOne.js",
                    "/simple-machines/src/components/shuttles/ShuttleTwo.js",
                    "/simple-machines/src/components/shuttles/ShuttleThree.js",
                    "/simple-machines/src/components/shuttles/ShuttleFour.js",
                    "/simple-machines/src/components/balls/SunBall.js",
                    "/simple-machines/src/components/balls/EarthBall.js",
                    "/simple-machines/src/components/balls/MoonBall.js",
                    "/simple-machines/src/components/balls/BubbleBall.js",
                    "/simple-machines/src/components/balls/SpacemanBall.js",
                    "/simple-machines/src/components/aliens/BlueAlien.js",
                    "/simple-machines/src/components/aliens/GreenAlien.js",
                    "/simple-machines/src/components/aliens/PinkAlien.js",
                    "/simple-machines/src/components/aliens/PurpleAlien.js",
                    "/simple-machines/src/components/Basket.js",
                    "/simple-machines/src/components/Trampoline.js",
                    "/simple-machines/src/app.js"
                ];
                for (let i = 0; i < scriptNames.length; i++) {
                    // let divScripts = document.getElementById('load-script');
                    let script = document.createElement('script');
                    script.src = scriptNames[i];
                    script.async = false; // This is required for synchronous execution
                    document.head.appendChild(script);
                    let scope = this;
                    script.addEventListener('load', function() {
                        if (++scriptsLoaded === scriptNames.length) {
                            // Everything was loaded, start the app
                            StartApp(scope);
                        }
                    });
                }
            }
        }
    },
    data() {
        return {
            cname: 'presence-smapp-' + '1234',
            channel: null
        }
    },
    created() {
    },
    destroyed() {
    },
    mounted() {
        this.tracking({
            type: 'page',
            title: '/playspace/simplemachines',
        });        
        this.init();
    }
};
</script> 

<style lang='scss'>
div.connections canvas {
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 68%;
}
</style>